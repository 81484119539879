import axios from 'axios';

const SetupAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000,
});

// Add request interceptor to set Authorization header before each request
SetupAxios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.request.use(request => {
    console.log('*** Starting Request ***')
    return request
})

export default SetupAxios;







// import axios, {AxiosInstance} from 'axios'
// import {localStorageKey, localStorageValue} from '../../app/modules/all-pages'
//
// const REACT_APP_API_URL = process.env.REACT_APP_API_URL
// let isRedirectingToLogin = false // Flag to prevent infinite redirects
//
//
// const SetupAxios: AxiosInstance = axios.create({
//     baseURL: REACT_APP_API_URL,
//     headers: {
//         'Content-Type': 'application/json',
//     },
//     // timeout: 10000,
// });
//
// SetupAxios.interceptors.request.use(
//     config => {
//         if (localStorageValue) {
//             config.headers.Authorization = `Bearer ${localStorageValue}`
//         }
//
//         return config
//     },
//     error => {
//         return Promise.reject(error)
//     },
// )
//
// SetupAxios.interceptors.response.use(
//     response => {
//         // Handle the response data here
//         return response
//     },
//     error => {
//         const {response} = error
//
//         if (response) {
//             const {status} = response
//             if (status === 401) {
//                 // Clear the auth token from localStorage (if needed)
//                 // window.localStorage.removeItem(localStorageKey)
//                 // window.localStorage.removeItem('_userInterface')
//                 // window.localStorage.removeItem('_clientCreationDate')
//
//                 // Redirect to login
//                 // window.location.href = '/login'; // Change this to your login route
//
//                 return
//             }
//         }
//
//         return Promise.reject(error)
//     },
// )
//
//
// // SetupAxios.interceptors.response.use(
// //     response => {
// //         // Handle the response data here
// //         return response;
// //     },
// //     error => {
// //         // Handle errors here
// //         return Promise.reject(error);
// //     }
// // );
//
// export default SetupAxios

